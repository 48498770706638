<template>
  <dialog-outline v-model="showDialog">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ title }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <record-banner :record="record"></record-banner>

      <!-- Upload file input -->
      <file-input
        @change="onFileInputChange"
        :progress-visible="visibleProgress"
        :progress-value="uploadFileProgress"
      >
        <template v-slot="{ progress }">
          <slot name="progressDisplay">
            {{ formatProgressText(progress.value) }}
          </slot>
        </template>
      </file-input>

      <!-- Compound Insert Options -->
      <v-col>
        <expansion-panels-layout :inset="true">
          <!-- header -->
          <template v-slot:header>
            Insert Options &emsp;
            <core-chip-group
              v-show="hasInsertOptions"
              :items="insertOptionsList"
              :column="true"
              :chip-small="visibleOnSmAndDown"
              :show-arrows="true"
            >
            </core-chip-group>
          </template>
          <template v-slot:content>
            <compound-insert-options
              :record="record"
              :siblings-count="getChildCount"
              :default-page="customSelectedPage"
              @insert-file="insertOption"
              @custom-page="customPageOption"
              @overwrite-option="overwriteOption"
            >
            </compound-insert-options>
          </template>
        </expansion-panels-layout>
      </v-col>

      <!-- Check In Options -->
      <v-col>
        <expansion-panels-layout :inset="true">
          <!-- header -->
          <template v-slot:header>
            Check In Options &emsp;
            <core-chip-group
              v-show="hasUploadOptions"
              :items="uploadOptionsList"
              :column="true"
              :chip-small="visibleOnSmAndDown"
              :show-arrows="true"
            >
            </core-chip-group>
          </template>
          <template v-slot:content>
            <check-in-option
              class="mt-4"
              :check-in-option="checkInOption"
            ></check-in-option>
          </template>
        </expansion-panels-layout>
      </v-col>
    </template>

    <!--  Check In Dialog Actions -->
    <template v-slot:actions>
      <template>
        <!-- perform Check In -->
        <base-button @click="onOk()" :disabled="!enableCommandUpload">{{
          commandUploadLabel
        }}</base-button>
      </template>
    </template>
    <template v-slot:actionCancel>
      <!-- cancel Dialog -->
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";
import { createCheckInOption } from "@/model/record/checkInModel";
import {
  fullRecordName,
  fullRecordVersionName,
  getImportedFileExtension,
  isCompoundFile
} from "@/services/record/recordService";
import { toLowerCaseSafe } from "@/utils";
import { findImageOperation, imageOperation } from "@/model/image/imageModel";
import { actionResultType } from "@/model/action/actionModel";
import { iconPage } from "@/design/icon/iconConst";
import { fileCheckInMixin } from "@/mixins/shared/file/fileCheckInMixin";
import { getDocumentFileCount } from "@/services/api/apiContent";

export default {
  name: "UploadCompoundDocumentDialog",
  mixins: [dialogOutlineMixin, recordBannerAble, fileCheckInMixin],
  components: {
    BaseButton: () => import("@/components/shared/base/BaseButton"),
    CheckInOption: () =>
      import("@/components/shared/core/checkIn/CheckInOption"),
    FileInput: () => import("@/components/shared/core/inputs/FileInput"),
    ExpansionPanelsLayout: () =>
      import("@/components/shared/core/expansionPanel/ExpansionPanelsLayout"),
    CompoundInsertOptions: () =>
      import("@/components/shared/core/checkIn/CompoundInsertOptions"),
    CoreChipGroup: () =>
      import("@/components/shared/core/chipGroup/CoreChipGroup")
  },
  props: {
    currentRecord: {
      type: Object,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    uploadDocumentEvent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      checkInOption: createCheckInOption(),
      iconPage: iconPage,
      siblingsCount: this.currentRecord?.children ?? 0,

      //compound Upload
      customSelectedPage: 1,
      insertFile: false,
      overwriteFile: false,
      insertOptionsList: ["Append"]
    };
  },
  computed: {
    record() {
      return this.currentRecord ?? undefined;
    },
    /**
     * compute whether Command Upload is enabled
     * @return {boolean} true if Command Upload is enabled
     */
    enableCommandUpload() {
      return this.uploadFileInput !== undefined;
    },

    /**
     * Import Operation
     * @return {{name: string, icon: string, label: string}} Import Operation
     */
    operation() {
      return findImageOperation(imageOperation.Import);
    },

    /**
     * Import operation Label
     * @return {string} Import operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Upload Dialog title
     * @return {string} Upload Dialog title
     */
    title() {
      return `${this.commandUploadLabel} File To ${this.fullRecordName}`;
    },

    /**
     * Upload command Label
     * @return {string} Upload command Label
     */
    commandUploadLabel() {
      return `Upload`;
    },

    /**
     * full Record Name
     * @return {string} full Record Name
     */
    fullRecordName() {
      return fullRecordName(this.record);
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.record?.recordType
      }: '${fullRecordVersionName(this.record)}'`;
    },

    /**
     * Get Upload File Progress
     * @return {Number}
     */
    uploadFileProgress() {
      return this.progress;
    },

    /**
     * determine whether Progress should be visible
     * @return {boolean}
     */
    visibleProgress() {
      return this.progress > 0;
    },

    /**
     * Get Number of Files in Compound Document
     * @returns {number|number}
     */
    getChildCount() {
      console.log(
        `${this.$options.name} getChildCount() this.siblingsCount:`,
        this.siblingsCount
      );
      return isCompoundFile(this.record)
        ? this.siblingsCount
        : this.record?.children;
    },

    /**
     * Verify if Insert Option List has data
     * @return {boolean}
     */
    hasInsertOptions() {
      return (this.insertOptionsList?.length ?? 0) > 0;
    }
  },
  methods: {
    /**
     * On File Input Change Event handler
     * @param file
     */
    onFileInputChange(file) {
      try {
        this.uploadFileInput = undefined;
        const ext = toLowerCaseSafe(getImportedFileExtension(file));
        console.log(`${this.$options.name} onFileInputChange() ext:`, ext);
        if (!ext) {
          this.clearAlert();
        } else {
          this.uploadFileInput = file;
          this.clearAlert();
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * On Upload Action
     */
    async onOk() {
      try {
        this.clearAlert();
        const insertPosition = this.getInsertOptionNumber();

        console.log(
          `${this.$options.name} onOk() Insert Position`,
          insertPosition
        );
        console.log(
          `${this.$options.name} onOk() checkInOption`,
          this.checkInOption
        );
        console.log(`${this.$options.name} onOk() file`, this.uploadFileInput);

        const result = await this.uploadDocumentEvent(
          insertPosition,
          this.checkInOption,
          this.uploadFileInput
        );
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlertError(
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.uploadFileInput = undefined;
      }
    },

    /**
     * prepare suitable insert Option to pass to webApi
     * @return {Number}
     */
    getInsertOptionNumber() {
      console.log(
        `${this.$options.name} getInsertOptionNumber() this.getChildCount:`,
        this.getChildCount
      );

      return this.getChildCount !== 0
        ? this.insertFile
          ? this.customSelectedPage
          : this.overwriteFile
          ? this.compoundFileOverwrite
          : 0
        : 0;
    },

    /**
     * format Progress Text
     * @param {Number|number} progress
     * @return {string}
     */
    formatProgressText(progress) {
      return `${progress} %`;
    },

    /**
     * get and set Compound Document File Count
     * Called by child of Compound Document
     * @returns {Promise<Number>}
     */
    async setSiblingCount() {
      try {
        await getDocumentFileCount(this.record?.ancestor?.id).then(response => {
          this.siblingsCount = response?.data ?? 1;
          console.log(
            `${this.$options.name} setSiblingCount() this.siblingsCount`,
            this.siblingsCount
          );
        });
        // setting default page number to file selected in compound tree
        this.customSelectedPage = Number(this.record?.name);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Set Insert Option for Compound Upload
     * @param option
     */
    insertOption(option) {
      this.insertFile = option;
    },

    /**
     * Set custom Page for Compound Upload
     * @param page
     */
    customPageOption(page) {
      this.customSelectedPage = page;
    },

    /**
     * Set Overwrite for Compound Upload
     * @param option
     */
    overwriteOption(option) {
      this.overwriteFile = option;
    },

    /**
     * Get Insert Expansion Panel Title with selected option Hint
     * @return {string}
     */
    insertTitle() {
      if (this.insertFile)
        return `Insert Before Page ${this.customSelectedPage}`;
      else if (this.overwriteFile)
        return `Overwrite Page ${this.customSelectedPage}`;
      else {
        return `Append`;
      }
    },

    /**
     * Update Insert Option list with selected Option
     * @return {string}
     */
    updateInsertOptionList() {
      this.insertOptionsList = [];
      if (this.insertFile)
        this.insertOptionsList.push(
          `Insert Before Page ${this.customSelectedPage}`
        );
      else if (this.overwriteFile)
        this.insertOptionsList.push(
          `Overwrite Page ${this.customSelectedPage}`
        );
      else {
        this.insertOptionsList.push(`Append`);
      }
    }
  },
  watch: {
    record() {
      if (!isCompoundFile(this.record)) {
        this.overwriteFile = false;
      }
    },
    insertFile() {
      this.updateInsertOptionList();
    },
    customSelectedPage() {
      this.updateInsertOptionList();
    },
    overwriteFile() {
      this.updateInsertOptionList();
    },
    checkInOption: {
      handler() {
        this.updateSelectedOptionList();
      },
      deep: true
    }
  },
  async updated() {
    // set compound Document File count if compound child record opened
    if (isCompoundFile(this.record)) {
      await this.setSiblingCount();
    }
  }
};
</script>

<style scoped></style>
